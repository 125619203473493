.layoutBox {
  background-color: #FFFFFF;
  padding: 0 0.2rem;
  margin-top: 0.8rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  min-height: 76.3vh;
}
.memberClub {
  background-color: #F7F7FA;
  font-family: PingFangSC-Regular;
}
.memberClub .diglogPStyle {
  margin: 0.2rem 0.2rem 0.2rem 2rem;
  font-size: 16px;
}
.memberClub .dialogStyle {
  margin: 0.4rem 0.4rem 0.1rem 0.4rem;
  padding: 0 0 0.2rem;
  border-bottom: 1px solid #f3f0f3;
}
.memberClub .diglogPStyle1 {
  font-size: 0.28rem;
  margin: 0.32rem 1.26rem 0.06rem 1.26rem;
  display: flex;
  justify-content: space-between;
}
.memberClub .diglogPStyle1 .psdInput {
  font-size: 16px;
  border: 0;
  flex: 1;
  display: block;
  width: 100%;
}
.memberClub .diglogPStyle2 {
  font-size: 0.24rem;
  padding: 0.12rem 0 0.46rem 0;
  display: flex;
}
.memberClub .diglogPStyle2 .label {
  margin-left: 52px;
  transform: scale(0.75);
}
.memberClub .diglogPStyle2 .price {
  margin-right: 0.08rem;
}
.memberClub .buttonOk {
  margin-top: 5.5rem;
  color: #FFFFFF;
  font-size: 0.32rem;
  border: 0;
  width: 100%;
  height: 0.88rem;
  line-height: 0.88rem;
  background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
  border-radius: 0.16rem;
}
.memberClub .content {
  position: relative;
  padding-top: calc(env(safe-area-inset-top) + 0.88rem);
  left: 0;
  width: 100%;
}
.memberClub .content::before {
  content: "";
  height: calc(env(safe-area-inset-top) + 2.95rem);
  width: 100%;
  background: url(../../assets/imgs/new_color/club_bg.png) no-repeat center top;
  background-size: 100% 100%;
  display: inline-block;
  position: absolute;
  top: 0;
}
.memberClub .userInfo {
  overflow: hidden;
  margin: 0 0.5rem;
  margin-top: 0.2rem;
  position: relative;
  z-index: 10;
}
.memberClub .userInfo .level {
  margin-left: 0.2rem;
}
.memberClub .userInfo .level span {
  height: 0.37rem;
  font-size: 0.28rem;
  line-height: 0.37rem;
  color: #EFBA0D;
  font-family: 'haose';
  font-weight: 550;
}
.memberClub .userInfo .level span img {
  margin-right: 0.05rem;
  width: 0.44rem;
  height: 0.2rem;
}
.memberClub .userInfo .userHead {
  background-color: #FFFFFF;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
}
.memberClub .userInfo .userName {
  margin-left: 0.2rem;
  font-size: 0.32rem;
  color: #FFFFFF;
}
.memberClub .userInfo .vipBox {
  margin-left: 0.2rem;
}
.memberClub .userInfo .vipBox .vipLevel {
  width: 1rem;
  height: 0.4rem;
  position: relative;
  font-size: 0.24rem;
  font-family: Bahnschrift;
  display: inline-block;
}
.memberClub .userInfo .vipBox .vipLevel img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.memberClub .userInfo .vipBox .vipLevel span {
  position: relative;
  z-index: 11;
  display: block;
  text-align: center;
  line-height: 0.4rem;
  font-weight: bold;
  font-size: 0.26rem;
}
.memberClub .userInfo .vipBox .expireDay {
  color: rgba(255, 255, 255, 0.79);
  line-height: 0.35rem;
}
.memberClub .userInfo .vipBox .expireDay .span1 {
  font-size: 0.24rem;
  font-weight: normal;
}
.memberClub .userInfo .vipBox .expireDay .span2 {
  margin-left: 0.1rem;
  font-size: 0.24rem;
}
.memberClub .banli .top {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
}
.memberClub .banli .top .kuai {
  margin-right: 0.22rem;
  width: 5px;
  height: 0.26rem;
  background: linear-gradient(131deg, #527EFF 0%, #214BF9 100%);
}
.memberClub .banli .top .mcTitle {
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.memberClub .banli .changeStyles {
  border-radius: 0.16rem;
  border: 1px solid #527EFF;
  text-align: center;
  padding: 0.3rem 0;
  overflow: hidden;
  color: #333333;
}
.memberClub .banli .changeStyles.checked {
  color: #fff;
  background-image: linear-gradient(135deg, #527EFF 0%, #5569FC 100%);
  background-color: ;
  position: relative;
  border: none;
}
.memberClub .banli .changeStyles.checked .dataPrice {
  color: #FFFFFF;
}
.memberClub .banli .mainBackColor1 p {
  color: #fff;
}
.memberClub .banli .dataTitle {
  font-size: 0.28rem;
}
.memberClub .banli .dataPrice {
  color: #5569FC;
  font-size: 0.44rem;
  font-weight: bold;
  margin-top: 0.1rem;
}
.memberClub .banli .dataPriceOld {
  color: #666666;
  font-size: 0.28rem;
  text-decoration: line-through;
  margin-top: 0.1rem;
}
.memberClub .banli .dataPriceOld.checked {
  color: #FFFFFF;
}
