


















































































































































































	.layoutBox {
		background-color: #FFFFFF;
		padding: 0 0.2rem;
		margin-top: 0.8rem;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		min-height: 76.3vh;
	}
    .memberClub {
		background-color: #F7F7FA;
		font-family: PingFangSC-Regular;
        .diglogPStyle {
            margin: 0.2rem 0.2rem 0.2rem 2rem;
			font-size: 16px;
        }
        .dialogStyle {
            margin: 0.4rem 0.4rem 0.1rem 0.4rem;
            padding:0 0 0.2rem;
            border-bottom: 1px solid #f3f0f3;
        }
        .diglogPStyle1{
            font-size: 0.28rem;
            // margin: 0.4rem 0.4rem 0.1rem 0.4rem;
			margin: 0.32rem 1.26rem 0.06rem 1.26rem;
            // padding: 0 0 0.2rem;
			display: flex;
			justify-content: space-between;
			.label {
				// width: 5em;
				// width: 9em;
			}
			.psdInput {
				font-size: 16px;
				border: 0;
				flex: 1;
				display: block;
				width: 100%;
			}
        }
		.diglogPStyle2{
		    font-size: 0.24rem;
			padding: 0.12rem 0 0.46rem 0;
			display: flex;
			.label {
				margin-left: 52px;
				transform: scale(0.75);
				// width: 5em;
				// width: 9em;
			}
			.price {
				margin-right: 0.08rem;
			}
		}

        .buttonOk{
            margin-top: 5.5rem;
            color: #FFFFFF;
			font-size: .32rem;
            border: 0;
            // border-radius: 5px;
            width: 100%;
			height: .88rem;
			line-height: .88rem;
			background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
			border-radius: .16rem;
        }
		.content {
			position: relative;
			// top: 0.88rem;
			padding-top: calc(env(safe-area-inset-top) + 0.88rem);
			left: 0;
			width: 100%;
			// z-index: 9999;
			&::before{
				content: "";
				height: calc(env(safe-area-inset-top) + 2.95rem);
				width: 100%;
				background: url(../../assets/imgs/new_color/club_bg.png) no-repeat center top;
				background-size: 100% 100%;
				display: inline-block;
				position: absolute;
				top: 0;
			}
		}
        .userInfo {
			// margin-top: -100px;
            // border-bottom: 0.12rem solid #F5F5F5;
            overflow: hidden;
            margin: 0 0.5rem;
			margin-top: 0.2rem;
			// padding-top: 0.2rem;
			position: relative;
			z-index: 10;
			.level {
				margin-left: 0.2rem;
				span {
					height: 0.37rem;
					font-size: 0.28rem;
					line-height: 0.37rem;
					color: #EFBA0D;
					font-family: 'haose';
					font-weight: 550;
					img {
						margin-right: 0.05rem;
						width: 0.44rem;
						height: 0.2rem;
					}
				}
			}

            .userHead {
				background-color: #FFFFFF;
                border-radius: 50%;
                width: 1.2rem;
                height: 1.2rem;
            }

            .userName {
				margin-left: 0.2rem;
                font-size: 0.32rem;
				// font-family: Bahnschrift;
				// line-height: .5rem;
				color: #FFFFFF;
                // font-weight: bold;
            }

            .vipBox {
				margin-left: 0.2rem;

                .vipLevel {
                    width: 1rem;
                    height: 0.4rem;
                    position: relative;
                    font-size: 0.24rem;
                    font-family: Bahnschrift;
                    display: inline-block;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    span {
                        position: relative;
                        z-index: 11;
                        display: block;
                        text-align: center;
                        line-height: 0.4rem;
                        font-weight: bold;
                        font-size: 0.26rem;
                    }
                }

                .expireDay {
					color: rgba(255, 255, 255, 0.79);
                    // display: inline-block;
					line-height: .35rem;

                    .span1 {
                        font-size: 0.24rem;
                        // font-family: Bahnschrift;
                        font-weight: normal;
                    }

                    .span2 {
                        margin-left: 0.1rem;
                        font-size: 0.24rem;
                        // font-weight: 500;
                        // color: #737373;
                        // opacity: 0.7;
                    }
                }
            }
        }

        .banli {
            .top {
            	// border-bottom: 0.01rem solid #D3D9DE;
            	padding: 0.2rem 0;
            	display: flex;
            	align-items: center;
            	.kuai {
            		margin-right: 0.22rem;
            		width: 5px;
            		height: 0.26rem;
            		background: linear-gradient(131deg, #527EFF 0%, #214BF9 100%);
            	}
            	.mcTitle {
            		font-size: 0.32rem;
            		font-family: PingFang SC;
            		font-weight: bold;
            		color: #333333;
            		// margin-bottom: .25rem;
            	}
            }
            .changeStyles{
                // border: 1px solid #94C7FC;
                border-radius: 0.16rem;
				border: 1px solid #527EFF;
                text-align: center;
                padding: 0.3rem 0;
				overflow: hidden;
				color: #333333;
				&.checked {
					color: #fff;
					background-image: linear-gradient(135deg, #527EFF 0%, #5569FC 100%);
					background-color: ;
					position: relative;
					border: none;
					// &::after {
					// 	content: '';
					// 	position: absolute;
					// 	right: 0;
					// 	bottom: 0;
					// 	width: .6rem;
					// 	height: .6rem;
					// 	background: url(../../assets/imgs/icon/member_icon_bottom.png) no-repeat right bottom;
					// 	background-size: contain;
					// }
					.dataPrice{
						color: #FFFFFF;
					}
				}
            }
            .mainBackColor1 p{
                color: #fff;
            }
            .dataTitle{
                font-size: 0.28rem;
                // font-weight: bold;
                // color: #000000;
            }
            .dataPrice{
				color: #5569FC;
                font-size: 0.44rem;
                // font-weight: bold;
				// font-family: Bahnschrift;
				font-weight: bold;
				margin-top: 0.1rem;
            }
            .dataPriceOld{
				color: #666666;
                font-size: 0.28rem;
                // font-weight: 500;
				// font-family: Bahnschrift;
                text-decoration: line-through;
				margin-top: 0.1rem;
				&.checked {
					color: #FFFFFF;
				}
            }
        }
    }

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;